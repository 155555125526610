import React from "react";
import "./AboutUs.css";
import contact from "../Images/Group-of-customer-service-representatives.jpg";
import oliveOilAbout from "../Images/Mr.Olive/packages/_KH13115.jpg";

export default function AboutUsSpain() {
  return (
    <>
      <div className="aboutUs ">
        <div className="container" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-md-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="text-white">sobre nosotros</h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container p-3">
        <div className="row p-3">
          <div className="col-md-8">
            <h4 style={{ color: "#4e732c" }}>campo de agricultura:</h4>
            <p>
              &emsp;El Sr. Olive comenzó en el campo del cultivo del olivo en
              1986 en la carretera del desierto de El Cairo-Alejandría,
              específicamente en el área (Al-Khatatba - Abu Ghaleb -
              International Road en El Alamein). Hoy en día, el área cultivada
              ha alcanzado las 1.000 hectáreas de aceitunas de diversos tipos,
              donde se utilizan métodos modernos en el riego, la fertilización y
              evitando cualquier rastro de pesticidas en el cultivo.
            </p>
            <h4 style={{ color: "#4e732c" }}>Campo de fabricación:</h4>
            <p>
              {" "}
              &emsp; La empresa se constituyó como empresa solidaria en 1998 en
              el sector de la producción y transformación de aceitunas de mesa.
              El proceso de producción y fabricación se lleva a cabo utilizando
              las últimas líneas de producción y envasado de acuerdo con las
              normas internacionales. La empresa se ha convertido en pionera en
              el campo de la fabricación y comercialización de productos a nivel
              local y mundial, con una capacidad de producción de 10.000
              toneladas de aceitunas. La compañía ha tendido a agregar otros
              productos como pimientos, verduras, encurtidos y alcachofas en
              escabeche.
            </p>
            <h4 style={{ color: "#4e732c" }}>Objetivo de la empresa:</h4>
            <p>
              {" "}
              &emsp; Nuestro objetivo es proporcionar estándares y
              especificaciones internacionales de calidad a nuestros clientes en
              todo el mundo mediante la expansión en la apertura de nuevos
              mercados y el aumento de la inversión.
            </p>
          </div>
          <div className="col-md-4 ">
            <img src={oliveOilAbout} className="oliveOilAbout" alt="" />
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> contáctenos</h6>

                  <p>cuando tienes una pregunta estamos aquí para ayudarte</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}
