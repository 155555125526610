import React from "react";
import "./AboutUs.css";
import contact from "../Images/Group-of-customer-service-representatives.jpg";
import oliveOilAbout from "../Images/Mr.Olive/packages/_KH13115.jpg";

export default function AboutUs() {
  return (
    <>
      <div className="aboutUs ">
        <div className="container" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-md-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
                // style={{position:'relative'}}
              >
                <h1
                  // style={{position:'absolute' , top:'130px', left:'230px'}}
                  className="text-white "
                >
                  ABOUT US
                </h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container p-3">
        <div className="row p-3">
          <div className="col-md-8">
            <h4 style={{ color: "#4e732c" }}>Agriculture field:</h4>
            <p>
              &emsp; Mr. Olive started in the field of olive cultivation in 1986
              on the Cairo-Alexandria Desert Road, specifically in the area
              (Al-Khatatba - Abu Ghaleb - International Road in El Alamein).
              Today, the cultivated area has reached 1,000 hectares of olives of
              various kinds, where modern methods are used in irrigation,
              fertilization, and avoiding any traces of pesticides in the crop.
            </p>
            <h4 style={{ color: "#4e732c" }}>Manufacturing field:</h4>
            <p>
              {" "}
              &emsp; The company was established as a solidarity company in 1998
              in the field of production and processing of table olives. The
              production and manufacturing process is carried out using the
              latest production and packaging lines in accordance with
              international standards. The company has become a pioneer in the
              field of manufacturing and marketing products locally and
              globally, with a production capacity of 10,000 tons of olives. The
              company has tended to add other products such as peppers,
              vegetables, pickles and pickled artichokes.
            </p>
            <h4 style={{ color: "#4e732c" }}>Company's goal:</h4>
            <p>
              {" "}
              &emsp; Our goal is to provide international quality standards and
              specifications to our customers all over the world by expanding in
              opening new markets and increasing investment.
            </p>
          </div>
          <div className="col-md-4 ">
            <img src={oliveOilAbout} className="oliveOilAbout" alt="" />
          </div>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="contactContect">
                  <h6> CONTACT US</h6>

                  <p>when you have a question we're here to help</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}
