import React from "react";
import "./SocialMediaIcons.css";
import { isMobile } from "react-device-detect";

const SocialMediaIcons = () => {
  return (
    <>
      {!isMobile ? (
        <div>
          {" "}
          <a href="https://wa.me/01014583439" target="_blank">
            <div
              className="scroll-top rounded-circle  d-flex justify-content-center align-items-center"
              style={{
                marginBottom: "1%",
                backgroundColor: "rgba(37, 211, 102,0.9)",
                width: "70px",
                height: "70px",
              }}
            >
              <i
                className="fa-brands fa-whatsapp fs-4 text-decoration-none text-white"
                aria-hidden="true"
              ></i>
            </div>
          </a>
          <a href="https://www.facebook.com/MasteroliveEgy" target="_blank">
            <div
              className="scroll-top rounded-circle  d-flex justify-content-center align-items-center"
              style={{
                marginBottom: "6.5%",
                backgroundColor: "rgba(24, 119, 242, 1)",
                width: "70px",
                height: "70px",
              }}
            >
              <i
                className="fa-brands fa-facebook fs-4 text-decoration-none text-white"
                aria-hidden="true"
              ></i>
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/mr-olive-company/"
            target="_blank"
          >
            <div
              className="scroll-top rounded-circle  d-flex justify-content-center align-items-center instagram"
              style={{
                marginBottom: "12%",
                background: "rgba(0, 119, 181, 1)",
                width: "70px",
                height: "70px",
              }}
            >
              <i
                className="fa-brands fa-linkedin fs-4 text-decoration-none text-white"
                aria-hidden="true"
              ></i>
            </div>
          </a>
        </div>
      ) : (
        <div>
          {" "}
          <a href="https://wa.me/01014583439" target="_blank">
            <div
              className="scroll-top rounded-circle  d-flex justify-content-center align-items-center"
              style={{
                marginBottom: "3%",
                backgroundColor: "rgba(37, 211, 102,0.9)",
                width: "50px",
                height: "50px",
              }}
            >
              <i
                className="fa-brands fa-whatsapp fs-5 text-decoration-none text-white"
                aria-hidden="true"
              ></i>
            </div>
          </a>
          <a href="https://www.facebook.com/MasteroliveEgy" target="_blank">
            <div
              className="scroll-top rounded-circle  d-flex justify-content-center align-items-center"
              style={{
                marginBottom: "18%",
                backgroundColor: "rgba(24, 119, 242, 1)",
                width: "50px",
                height: "50px",
              }}
            >
              <i
                className="fa-brands fa-facebook fs-5 text-decoration-none text-white"
                aria-hidden="true"
              ></i>
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/mr-olive-company/"
            target="_blank"
          >
            <div
              className="scroll-top rounded-circle  d-flex justify-content-center align-items-center instagram"
              style={{
                marginBottom: "33%",
                background: "rgba(0, 119, 181, 1)",
                width: "50px",
                height: "50px",
              }}
            >
              <i
                className="fa-brands fa-linkedin fs-5 text-decoration-none text-white"
                aria-hidden="true"
              ></i>
            </div>
          </a>
        </div>
      )}
    </>
  );
};

export default SocialMediaIcons;
