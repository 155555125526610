import React from "react";
import "./AboutUs.css";
import contact from "../Images/Group-of-customer-service-representatives.jpg";
import oliveOilAbout from "../Images/Mr.Olive/packages/_KH13115.jpg";
export default function AboutUsAr() {
  return (
    <>
      <div className="aboutUs ">
        <div className="container" style={{ height: "100%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-md-12 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <h1 className="text-white">أقرا عنا </h1>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container p-3">
        <div
          className="row p-3"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div className="col-md-4 ">
            <img src={oliveOilAbout} className="oliveOilAbout" alt="" />
          </div>
          <div className="col-md-8">
            <h4
              style={{
                color: "#4e732c",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              :مجال الزراعة
            </h4>
            <br />
            <p dir="rtl" lang="ar">
              &nbsp;&nbsp; كانت بداية شركة مستر اوليف في مجال زراعة الزيتون في
              عام 1986 في طريق مصر اسكندرية الصحراوي تحديدا في منطقة ( الخطاطبة
              – ابو غالب – الطريق الدولي بالعلمين ). واليوم وصلت المساحة
              المزروعة الى 1000 هكتار من الزيتون بانواعه المختلفة حيث يتم
              استخدام الطرق الحديثة في الري، التسميد، وتجنب وجود اي اثار لمبيدات
              بالمحصول.
            </p>
            <h4
              style={{
                color: "#4e732c",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              :مجال التصنيع
            </h4>
            <p dir="rtl" lang="ar">
              &nbsp;&nbsp; تاسست شركة مستراوليف للصناعات الغذائية كشركة تضامن
              عام 1998 في مجال انتاج وتصنيع زيتون المائدة. تتم عملية الأنتاج و
              التصنيع باستخدام احدث خطوط الانتاج والتعبئة طبقا للمواصفات
              القياسية العالمية. اصبحت الشركة رائدة في مجال التصنيع وتسويق
              المنتجات محليا وعالميا حيث تبلغ الطاقة الانتاجية من الزيتون 10
              الاف طن و قد اتجهت الشركة لاضافة منتجات اخرى مثل الفلفل، الخضروات،
              المخلل والخرشوف المخلل.
            </p>
            <h4
              style={{
                color: "#4e732c",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              :هدف الشركة
            </h4>
            <p dir="rtl" lang="ar">
              &nbsp;&nbsp; هدفنا هو توفير معايير الجودة والمواصفات القياسية
              العالمية لعملائنا في كل انحاء العالم عن طريق التوسع في فتح اسواق
              جديدة وزيادة الاستثمار.
            </p>
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{
          backgroundColor: "#4e732c",
          color: "white",
          margin: "0",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="col-md-7 col-sm-8" style={{ marginTop: "25px" }}>
          <div className="container">
            <div className="row ">
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="contactContect">
                  <h6 style={{ display: "flex", justifyContent: "end" }}>
                    {" "}
                    اتصل بنا
                  </h6>

                  <p>عندما يكون لديك سؤال فنحن هنا للمساعدة</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-1 col-sm-4"
          style={{ backgroundColor: "#4e732c" }}
        ></div>
        <div className="col-md-4 col-sm-12 " style={{ padding: "0" }}>
          <img src={contact} alt="" className="contactMeFooter" />
        </div>
      </div>
    </>
  );
}
